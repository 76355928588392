<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="datepicker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <DatePicker :input-attributes="{class: 'form-control'}" v-model="state.optionFields.startDate.val" />
            </div>
            <div class="datepicker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker :input-attributes="{class: 'form-control'}" v-model="state.optionFields.endDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="1" :false-value=null v-model="state.optionFields.includeCdiStock.val"> Include CDI Stock</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="1" :false-value=null v-model="state.optionFields.remainingProduction.val"> Remaining Production</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="1" :false-value=null v-model="state.optionFields.showAllLocationTotal.val"> Show all location totals</label>
            </div>
        </div>
    </div>
</template>

<script>

import {store} from '@/store/Report.store';
import DatePicker from "@/components/utils/DatePicker.vue";
import cdiLocationCache from "@/cache/cdiLocation.cache";

export default {
    name: 'Options',
    components: {DatePicker},
    data() {
        return {
            state: store.state,
            toggleHide: true,
            cache: {
                cdiLocationCache: cdiLocationCache.CDI_LOCATIONS
            }
        }
    }
}
</script>