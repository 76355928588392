<template>
    <div>
        <h1>Item Build Progress</h1>
        <div class="alert alert-warning">
            This report is deployed to production for observation and debugging purposes using live data. <br>
            The remaining production includes invoice items that did <strong>NOT</strong> go through the production process and will not show $0 when these
            item types are present.
        </div>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>

import {store} from '@/store/Report.store';
import Options from './build_progress_opt.vue';
import Results from './utils/Results.vue';
import Fields from './utils/Fields.vue';

export default {
    name: 'build_progress',
    components: {
        Fields,
        Results,
        Options
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                startDate:              {val: new Date().toLocaleDateString(), qsField: '[dates][build_date_from]'},
                endDate:                {val: new Date().toLocaleDateString(), qsField: '[dates][build_date_to]'},
                cdiLocationId:          {val: '0', qsField: '[numbers][t.cdi_location_id]'},
                includeCdiStock:        {val: null, qsField: '[include_cdi_stock]'},
                remainingProduction:    {val: null, qsField: '[remaining_value]'},
                showAllLocationTotal:   {val: null, qsField: '[show_all_location_totals]'}
            },
            groupByFields: {},
            dataFields: {
                tracker_id:             {label: 'Order', checked: true},
                nomenclature:           {label: 'Item', checked: true},
                cdi_location:           {label: 'CDI Location', checked: true},
                job_tag:                {label: 'Job Tag', checked: false},
                distributor_name:       {label: 'Customer', checked: true},
                email:                  {label: 'Email', checked: 'false'},
                distributor_po:         {label: 'Purchase Order #', checked: true},
                scheduled_ship:         {label: 'Scheduled Ship', checked: true},
                actual_ship:            {label: 'Actual Ship', checked: false},
                final_price:            {label: 'Price', checked: true},
            }
        }
    },
    methods: {
        runReport: function() {
            store.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Build Progress']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields)
    }
}

</script>